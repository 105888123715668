import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import { updateStudio } from '../services/api'

import { NotificationManager, NotificationContainer } from 'react-notifications'
import SetupHeader from '../Components/SetupHeader'
import PrimaryButton from '../Components/PrimaryButton'
import ToolTip from '../Components/ToolTip'
import CheckBoxIcon from '../Components/Icons/CheckBoxIcon'

import styles from './Styles/ConfigureClassesScreenStyles'
import { Colors, Metrics } from '../Themes'

class ConfigureClassesScreen extends Component {
  state = {
    selectedClasses: [],
  }

  componentDidMount = () => {
    const { studio } = this.props
    this.setState({ selectedClasses: studio.class_types })
  }

  handleEmpty = () =>
    NotificationManager.error('Please select at lease one class type for your studio.')

  handleNext = () => (window.location.href = this.props.next_path)

  renderClassTypesList = () => (
    <form style={styles.checkBoxGroup}>
      {this.props.class_types.map(classType => {
        const isSelected = this.isSelected(classType)
        return (
          <div
            className="input-label"
            style={styles.checkBoxListItem}
            onClick={event => this.toggleClassType(classType, event)}
            key={classType.name}
          >
            <CheckBoxIcon
              isSelected={isSelected}
              iconSize="24"
              outlineColor={isSelected ? Colors.black75 : Colors.black20}
            />
            <p style={styles.checkBoxLabel}>{classType.name}</p>
          </div>
        )
      })}
    </form>
  )

  isSelected = classType =>
    this.state.selectedClasses.some(selected => selected.id === classType.id)

  toggleClassType = (classType, event) => {
    event.preventDefault()
    const { studio } = this.props
    let { selectedClasses } = this.state
    if (this.isSelected(classType)) {
      selectedClasses = selectedClasses.filter(selected => selected.id !== classType.id)
    } else {
      selectedClasses.push(classType)
    }

    updateStudio({
      studio,
      attributes: { class_type_ids: selectedClasses.map(classType => classType.id) },
    }).then(response => this.handleResponse(response, selectedClasses))
  }

  handleResponse = (response, selectedClasses) => {
    if (response.ok) {
      this.setState({ selectedClasses })
    } else {
      NotificationManager.error('There has been an error, please try again later.')
    }
  }

  render() {
    const {
      studio: { name: studioName },
    } = this.props
    const { selectedClasses } = this.state
    const hasClasses = selectedClasses.length > 0

    const classTypesToolTipContent = (
      <>
        <p>Instrukt uses class types to categorize classes and instructors.</p>
        <br />
        <p>Using class types, you have control of which instructors are allowed to teach and substitute each class on your schedule.</p>
        <br/>
        <p>Select the initial class types you will use at your studio to separate your classes in to groups.</p>
      </>
    );

    return (
      <Fragment>
        <NotificationContainer />
        <div className="screen-background">
          <div className="main-container">
            <SetupHeader />
            <div style={styles.headerTextWrapper}>
              <p style={styles.headerMainText}>Choose Class Types</p>
              <ToolTip
                tooltipId="choose-classes"
                content={classTypesToolTipContent}
              />
            </div>
            <div style={styles.infoContainer}>
              <p style={styles.infoText} className="info-text">
                Scroll through and select the class types taught at {studioName}
              </p>
            </div>
            {this.renderClassTypesList()}
            <div style={styles.buttonsContainer} className="buttons-container">
              <PrimaryButton
                color={hasClasses ? Colors.primaryTheme : Colors.black50}
                onPress={hasClasses ? this.handleNext : this.handleEmpty}
                text="Next"
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

ConfigureClassesScreen.propTypes = {
  studio: PropTypes.object,
  class_types: PropTypes.array,
  next_path: PropTypes.string,
}

export default ConfigureClassesScreen

import React from 'react'

import SetupHeader from '../Components/SetupHeader'
import PrimaryButton from '../Components/PrimaryButton'
import LinkButton from '../Components/LinkButton'
import ToolTip from '../Components/ToolTip'

import styles from './Styles/CreateResourceScreenStyles'
import { Colors, Metrics } from '../Themes'

const LandingToolTipContent =
  <>
    <p>Select ‘Studio Owner’ only if you plan to set up a new studio account on Instrukt.</p>
    <br />
    <p>
      To complete owner setup, you must have owner credentials for your studio’s Mindbody account and the ability to enter credit card payment information
      for your studio.
    </p>
    <br />
    <p>Otherwise select ‘Instructor’.</p>
  </>;

const LandingScreen = () => {
  const goToSignIn = () => (window.location.pathname = '/users/sign_in')
  const goToSignUp = isOwner => (window.location.href = `/users/sign_up?owner=${isOwner}`)

  return (
    <div className="screen-background">
      <div className="main-container">
        <SetupHeader />
        <div style={{ marginBottom: Metrics.fatMargin * 2, flexDirection: 'column' }}>
          <p style={styles.headerMainText}>Welcome, are you an Instructor</p>
          <div style={styles.headerTextWrapper}>
            <p style={styles.headerMainText}>or a Studio Owner?</p>
            <ToolTip
              tooltipId="landing-tooltip"
              content={LandingToolTipContent}
            />
          </div>
        </div>
        <div style={styles.buttonsContainer} className="buttons-container">
          <PrimaryButton onPress={() => goToSignUp(false)} text={'Instructor'} width={'12vw'} newCtaButton />
          <PrimaryButton
            color={Colors.darkBlue}
            onPress={() => goToSignUp(true)}
            text={'Studio Owner'}
            width={'12vw'}
            newCtaButton
          />
        </div>
        <div style={styles.linksContainer}>
          <LinkButton linkAction={goToSignIn} linkText={'Already have an account?'} />
        </div>
      </div>
    </div>
  )
}

export default LandingScreen

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager, NotificationContainer } from 'react-notifications';

import { logout, submitSubscription, validatePromoCode, addSubscription } from '../services/api';

import SetupHeader from '../Components/SetupHeader';
import SubscriptionCard from '../Components/SubscriptionCard';
import LinkButton from '../Components/LinkButton';
import PaymentSetupScreen from './PaymentSetupScreen';

import styles from './Styles/SubscriptionsScreenStyles';
import BackButton from '../Components/BackButton';
import Stepper from '../Components/Stepper';
import { STEPS, SUBSCRIPTION_TYPES, URLS } from '../utils/constants';
import { Button, Modal } from 'antd';
import { ToolTip } from '../Components/ToolTip';

class SubscriptionConfirmationScreen extends Component {
  static propTypes = {
    subscription: PropTypes.object,
    plans: PropTypes.object,
  };

  state = {
    addonCount: 0,
    promoCode: '',
    promoCodeValidated: false,
    promoCodeMessage: '',
    startingPlan: false,
    upgrading: false,
    applyingPromotion: false,
    paymentModalVisible: false,
  };

  componentDidMount = () => {
    const {
      plans: { premium },
      subscription: {
        object_data: { plan_type: subscriptionType },
        studio_counts: { location_count: locationCount },
      },
    } = this.props;

    const maxPremiumLocations = parseInt(premium[0].metadata.max_locations, 10);
    const addonCount =
      locationCount > maxPremiumLocations ? locationCount - maxPremiumLocations : 0;
    this.setState({ addonCount, subscriptionType, locationCount });
  };

  getTotalCost = subscriptionType => {
    const { plans } = this.props;
    const { addonCount } = this.state;

    const basicPlan = plans.basic[0];
    const premiumPlan = plans.premium[0];
    const addonPlan = plans.premium[1];

    let planTotal = 0;

    if (subscriptionType === 'basic') {
      planTotal = basicPlan.amount / 100;
    } else if (subscriptionType === 'premium') {
      planTotal = (premiumPlan.amount + addonPlan.amount * addonCount) / 100;
    }

    return planTotal;
  };

  handleCancel = () => logout().then(() => (window.location.href = '/users/sign_in'));

  handlePressSubmit = subscriptionType => {
    if (this.promoCodeValidated()) {
      this.setState({ startingPlan: true }, () => {
        submitSubscription(subscriptionType).then(response => this.handleResponse(response));
      });
    }
  };

  handleModalCancel = () => this.handleTogglePaymentModal(false);

  promoCodeValidated = () => {
    const { promoCode, promoCodeValidated } = this.state;
    if (promoCodeValidated || promoCode === '') {
      return true;
    } else {
      NotificationManager.error("Click 'apply' first to validate your promo code.");
    }
  };

  handleResponse = response => {
    if (response.ok) {
      NotificationManager.success('Success!');
      this.setState({ startingPlan: false });
      window.location.replace(URLS.ACCOUNT_SETUP_OWNER);
    } else {
      NotificationManager.error(response.message);
      this.setState({ startingPlan: false });
    }
  };

  handleSubmitPromo = () => {
    const { promoCode } = this.state;
    this.setState(
      {
        applyingPromotion: true,
      },
      () => {
        validatePromoCode(promoCode).then(response => this.handlePromoResponse(response));
      }
    );
  };

  handlePromoResponse = response => {
    if (response.ok) {
      NotificationManager.success(response.message);
      const { metadata } = response.coupon;
      this.setState({
        promoCodeValidated: true,
        promoCodeMessage: metadata.description,
        applyingPromotion: false,
      });
    } else {
      NotificationManager.error(response.message);
      this.setState({ promoCode: '', applyingPromotion: false });
    }
  };

  handleTogglePaymentModal = (paymentModalVisible = false) => {
    this.setState({
      paymentModalVisible,
    });
  };

  renderSubscriptionCard = (subscriptionType, subscription, studioCounts) => (
    <SubscriptionCard
      getTotalCost={this.getTotalCost}
      hasButton={false}
      plans={this.props.plans}
      studioCounts={studioCounts}
      submitSubscription={submitSubscription}
      subscription={subscription}
      subscriptionType={subscriptionType}
    />
  );

  addonText = (maxLocations = 0, addonCost = 0) => (
    <div style={{ flexDirection: 'column' }}>
      <div>
        The Premium includes {maxLocations} location. You will be billed ${addonCost} for each
        additional location.
      </div>
      <div>All locations must be under the same Mindbody siteID.</div>
      <div>If you have multiple siteIDs a separate Instrukt subscription is required for each.</div>
    </div>
  );

  maxBasicInstructorsWarning = (maxInstructors = 0) => (
    <div>
      <div>
        The Basic plan allows for a total of {maxInstructors} instructors to be active in Instrukt
        at a time.
      </div>
      <div>
        If you activate more than {maxInstructors} instructors you will need to upgrade to the
        Premium plan, or if you plan on activating more than {maxInstructors} instructors you may
        choose the Premium plan now.
      </div>
      <div />
    </div>
  );

  UpgradeToPremium = ({ loading = false, additionalLocations = 'multiple' }) => {
    const { plans = [] } = this.props;
    const premiumPlan = plans.premium[0];
    const planTitle = premiumPlan.metadata.plan_title;
    return (
      <div className="upgrade-plan-container">
        <div>
          <img src={require('../../assets/images/stubs/cloud.svg')} />
          <div className="upgrade-plan-text-container">
            <div className="upgrade-plan-title">Upgrade Your Plan</div>
            <div className="upgrade-plan-info">
              <div>
                There are <span>{additionalLocations}</span> locations associated with this MINDBODY
                site ID.
                <br />
                The Premium Plan is required when you have more than one locations under one site
                ID.
              </div>
              <div>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => this.upgradeSubscription()}
                  loading={loading}
                >
                  Upgrade to {planTitle}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  PlanSummary = ({ plan = {} }) => {
    const planCost = plan.amount / 100;
    const additionalCost = plan.addonAmount * plan.addonCount;
    const totalCost = planCost + additionalCost;
    const isPremiumPlan = plan.type === SUBSCRIPTION_TYPES.PREMIUM;
    return (
      <div className="plan-summary">
        <div className="plan-title">{plan.title}</div>
        <div className="plan-price">
          <div>Plan Price</div>
          <div>${planCost}</div>
        </div>
        {isPremiumPlan ? (
          <div className="plan-additional-price">
            <div>
              Additional Locations
              <ToolTip
                tooltipId="additional-locations"
                content={`$${additionalCost} = $${plan.addonAmount} per location x ${
                  plan.addonCount
                } location${true ? 's' : ''}`}
              />
            </div>
            <div>${additionalCost}</div>
          </div>
        ) : null}
        <div className="plan-total">
          <div>Total</div>
          <div>${isPremiumPlan ? totalCost : planCost}</div>
        </div>
      </div>
    );
  };

  PlanFeatures = ({ plan = {} }) => {
    return (
      <div className="plan-features">
        <div className="plan-title">Features</div>
        <div className="features-container">
          <div>
            <div>
              {plan.trialPeriod} day
              {plan.trialPeriod > 1 ? 's' : ''} FREE trial
            </div>
            <div>Integration with one MINDBODY Site ID</div>
          </div>
          {plan.type === SUBSCRIPTION_TYPES.PREMIUM ? (
            <Fragment>
              <div>
                <div>Support for multiple locations under one MINDBODY Site ID</div>
                <div>
                  {plan.maxLocations} studio location included
                  <ToolTip tooltipId="max-locations" content={this.addonText(plan.maxLocations, plan.addonAmount)} />
                </div>
              </div>
              <div>
                <div>Active instructors: {plan.maxInstructors}</div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div>
                <div>For studios with only {plan.maxLocations} location</div>
                <div>
                  Active instructors: Upto {plan.maxInstructors}
                  <ToolTip tooltipId="instructors-tooltip" title={this.maxBasicInstructorsWarning(plan.maxInstructors)} />
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  };

  PaymentMethod = () => (
    <div className="payment-method-container">
      <div className="payment-method-title">Payment Method</div>
      <div className="edit-button">Edit</div>
    </div>
  );

  handleUpgradeResponse = response => {
    if (response.ok) {
      const {
        plans: { premium },
      } = this.props;
      const {
        subscription: {
          object_data: { plan_type: subscriptionType },
          studio_counts: { location_count: locationCount },
        },
      } = response;
      const maxPremiumLocations = parseInt(premium[0].metadata.max_locations, 10);
      const addonCount =
        locationCount > maxPremiumLocations ? locationCount - maxPremiumLocations : 0;
      this.setState({ subscriptionType, addonCount });
    } else {
      NotificationManager.error(response.message);
    }
  };

  upgradeSubscription = () => {
    this.setState({ upgrading: true }, () =>
      addSubscription('premium').then(response => {
        this.handleUpgradeResponse(response);
        this.setState({ upgrading: false });
      })
    );
  };

  updateInput = (input, attr) => this.setState({ [attr]: input.target.value });

  isValidPlan = (type, maxLocations = 1) => {
    if (type === SUBSCRIPTION_TYPES.BASIC) {
      const { subscription = {} } = this.props;
      const {
        studio_counts: { location_count: locationCount },
      } = subscription;
      return locationCount <= maxLocations;
    }
    return true;
  };

  render() {
    const { plans } = this.props;

    const {
      promoCode,
      addonCount = 0,
      subscriptionType,
      upgrading = false,
      locationCount = 0,
      applyingPromotion = false,
      startingPlan = false,
      promoCodeValidated = false,
      promoCodeMessage = '',
    } = this.state;

    const basicPlan = plans.basic[0];
    const premiumPlan = plans.premium[0];
    const addonPlan = plans.premium[1];
    const addonAmount = addonPlan.amount;

    const maxLocations = basicPlan.metadata.max_locations;
    const isValidPlan = this.isValidPlan(subscriptionType, maxLocations);
    const selectedPlan = subscriptionType === SUBSCRIPTION_TYPES.PREMIUM ? premiumPlan : basicPlan;
    const planDetails = {
      title: selectedPlan.metadata.plan_title,
      amount: selectedPlan.amount,
      type: subscriptionType,
      addonAmount: addonAmount / 100,
      maxLocations: selectedPlan.metadata.max_locations,
      maxInstructors: selectedPlan.metadata.max_instructors,
      trialPeriod: selectedPlan.metadata.trial_period,
      addonCount,
    };

    return (
      <Fragment>
        <div className="screen-background">
          <BackButton />
          <div className="main-container no-padding">
            <div className="fix-align">
              <NotificationContainer />
            </div>
            <SetupHeader />
            <Stepper steps={STEPS.ACCOUNT_CREATION} current={4} className="account-stepper" />
            <div style={styles.headerTextWrapper}>
              <p style={styles.headerMainText}>
                {isValidPlan
                  ? 'Begin your Subscription'
                  : 'Confirm Subscription and Complete Payment'}
              </p>
            </div>
            {isValidPlan ? (
              <Fragment>
                <this.PlanSummary plan={planDetails} />
                <this.PlanFeatures plan={planDetails} />
                <label
                  className="input-label"
                  style={{
                    ...styles.bottomLabel,
                    minWidth: 'unset',
                    width: 'calc(100% - 372px)',
                    paddingRight: 0,
                  }}
                >
                  <input
                    placeholder="Promotional Code"
                    style={{
                      ...styles.input,
                      paddingLeft: 0,
                      width: applyingPromotion ? 'calc(100% - 32px)' : 'calc(100% - 98px)',
                      fontSize: 14,
                    }}
                    type="text"
                    value={promoCode}
                    onChange={input => this.updateInput(input, 'promoCode')}
                    disabled={applyingPromotion}
                  />
                  <LinkButton
                    linkAction={this.handleSubmitPromo}
                    linkText={'Apply Code'}
                    loading={applyingPromotion}
                    linkStyle={{ fontSize: 14, fontWeight: 500 }}
                  />
                </label>
                {promoCodeValidated && <div style={styles.promoMessage}>{promoCodeMessage}</div>}
                <div
                  style={{
                    ...styles.buttonsContainer,
                    width: 'calc(100% - 580px)',
                  }}
                  className="buttons-container confirm-subscription"
                >
                  <Button
                    style={{
                      backgroundColor: 'darkgrey',
                      color: 'white',
                    }}
                    size="large"
                    onClick={this.handleCancel}
                    disabled={startingPlan}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => this.handlePressSubmit(subscriptionType)}
                    loading={startingPlan}
                  >
                    Start Plan
                  </Button>
                </div>
                <Button style={{ margin: '16px 0' }} type="link" onClick={() => this.handleTogglePaymentModal(true)}>
                  Update Payment Information
                </Button>
                <Modal
                  title="Basic Modal"
                  visible={this.state.paymentModalVisible}
                  onOk={this.handleOk}
                  onCancel={this.handleModalCancel}
                  footer={null}
                  title={null}
                  closable={false}
                  destroyOnClose
                >
                  <PaymentSetupScreen
                    handleCancel={this.handleModalCancel}
                    serverProps={this.props.serverProps}
                    isModal={true}
                  />
                </Modal>
              </Fragment>
            ) : (
              <this.UpgradeToPremium loading={upgrading} additionalLocations={locationCount} />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SubscriptionConfirmationScreen;
